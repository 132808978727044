import mainvisualBackgraound from "../../resources/images/mainvisual_backgraound.png";
import { Box, SxProps, Theme, Typography } from "@mui/material";

export const MainVisual = () => {
  return (
    <Box sx={sx} className="MainVisual">
      <Box className="MainVisual-Background">
        <Typography className="MainVisual-Title">
          <span className="MainVisual-Title-Strong">安心</span>と<span className="MainVisual-Title-Strong">信頼</span>×
          <br />
          <span className="MainVisual-Title-Strong">高度</span>な<br className="MainVisual-Title-BrSp" />
          <span className="MainVisual-Title-Strong">技術力</span>
        </Typography>
        <Typography className="MainVisual-Subtitle">高品質な開発を基準に</Typography>
      </Box>
    </Box>
  );
};

const sx: SxProps<Theme> = (theme) => ({
  "&.MainVisual": {
    width:"100%",
    height: {
      md: "700px",
      xs: "550px",
    },
    fontFamily: "Noto Sans JP",
  },
  ".MainVisual-Background": {
    height: {
      md: "700px",
      xs: "550px",
    },
    overflow: "hidden",
    left: 0,
    right: 0,
    backgroundImage: `url(${mainvisualBackgraound})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  ".MainVisual-Title": {
    position: "relative",
    fontWeight: "700",
    fontSize: {
      md: "64px",
      xs: "38px",
    },
    color: "#FFFFFF",
    marginTop: {
      md: "276px",
      xs: "150px",
    },
    marginLeft: {
      md: "100px",
      xs: "32px",
    },
  },
  ".MainVisual-Title-Strong": {
    fontSize: {
      md: "96px",
      xs: "58px",
    },
  },
  ".MainVisual-Title-BrSp": {
    display: {
      md: "none",
    },
  },
  ".MainVisual-Subtitle": {
    fontFamily: "Noto Sans JP",
    fontWeight: 500,
    fontSize: {
      md: "48px",
      xs: "24px",
    },
    lineHeight: {
      md: "72px",
      xs: "36px",
    },
    letterSpacing: "2px",
    color: "#FFFFFF",
    marginTop: "12px",
    marginLeft: {
      md: "100px",
      xs: "36px",
    },
  },
});
