import aboutIllustration from "../../resources/images/about_illustration.png";
import { Box, SxProps, Theme, Grid } from "@mui/material";

export const About = () => {
  return (
    <Box sx={sx} className="About">
      <Grid container className="About-Title">
        <span className="About-Title-Head">A</span>BOUT
      </Grid>
      <Grid container className="About-SubTitle">
        私たちについて
      </Grid>
      <Box className="About-Illustration" component="img" alt="イラスト" src={aboutIllustration} />
      <Box className="About-Description">
        副業人材を主体とした<span className="About-Description-Strong">「業務委託サービス」</span>です。
        <br className="About-Description-PcBr" />
        通常では人材派遣の市場に出てこないような大企業に勤めているベテランのエンジニアや開発の中核を担う
        <br className="About-Description-PcBr" />
        フリーランスエンジニアが<span className="About-Description-Strong">現在約100名</span>ほど所属しています。
        <br />
        <br />
        副業を主体としているため長時間の拘束稼働はできませんが、
        <br className="About-Description-PcBr" />
        トップレベルの知識と経験を活かして複数人でチームとなってクライアントの業務を請け負います。
        <br className="About-Description-PcBr" />
        クライアント側のチームメンバーと共同して開発を行うこともできるため、
        <br className="About-Description-PcBr" />
        <span className="About-Description-Strong">クライアント所属のエンジニアのスキルアップ</span>
        を行うことも可能です。
      </Box>
    </Box>
  );
};

const sx: SxProps<Theme> = (theme) => ({
  "&.About": {
    width: "100%",
    background: "rgba(34, 161, 185, 0.1)",
    padding: "60px 0 92px",
  },
  ".About-Title": {
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Avenir Next",
    fontWeight: 500,
    fontSize: {
      md: "64px",
      xs: "40px",
    },
    lineHeight: "88px",
    letterSpacing: "0.15em",
  },
  ".About-Title-Head": {
    color: "#22A1B9",
    fontWeight: 700,
  },
  ".About-SubTitle": {
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 700,
    fontSize: {
      md: "16px",
      xs: "14px",
    },
    lineHeight: {
      md: "24x",
      xs: "20px",
    },
    letterSpacing: "1px",
  },
  ".About-Illustration": {
    marginTop: "21px",
    width: "100%",
  },
  ".About-Description": {
    width: {
      md: "auto",
      xs: "332px",
    },
    margin: "21px auto 0",
    textAlign: "center",
    fontSize: {
      md: "18px",
      xs: "16px",
    },
    fontWeight: 400,
    lineHeight: "35px",
  },
  ".About-Description-PcBr": {
    display: {
      md: "block",
      xs: "none",
    },
  },
  ".About-Description-Strong": {
    color: "#22A1B9",
    fontWeight: 700,
  },
});
