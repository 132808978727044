import { Box, SxProps, Theme, Grid, Link } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

export const Company = () => {
  return (
    <Box sx={sx} className="Company">
      <Grid container className="Company-Title">
        C<span className="Company-Title-Body">OMPANY</span>
      </Grid>
      <Grid container className="Company-SubTitle">
        会社概要
      </Grid>
      <TableContainer className="Company-Content">
        <Table className="Company-Content-Table">
          <TableBody>
            <TableRow>
              <TableCell className="Company-Content-Table-Head">会社名</TableCell>
              <TableCell className="Company-Content-Table-Body">株式会社テクノスタンダード</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="Company-Content-Table-Head">設立日</TableCell>
              <TableCell className="Company-Content-Table-Body">2022年6月23日</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="Company-Content-Table-Head">所在地</TableCell>
              <TableCell className="Company-Content-Table-Body">〒150-0002 東京都渋谷区渋谷3-10-15 YKビル7F</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="Company-Content-Table-Head">資本金</TableCell>
              <TableCell className="Company-Content-Table-Body">100万円</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="Company-Content-Table-Head">代表取締役</TableCell>
              <TableCell className="Company-Content-Table-Body">加藤拓也</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="Company-Content-Table-Head">
                取引銀行
                <br />
                <br />
              </TableCell>
              <TableCell className="Company-Content-Table-Body">
                三菱UFJ銀行
                <br />
                SBIネット銀行
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="Company-Content-Table-Head Supplier">取引先</TableCell>
              <TableCell className="Company-Content-Table-Body Supplier">
                <Link className="Company-Content-Table-Body-Link" target="_blank" href="https://aqua-aris.com/">
                  AQUA ARIS株式会社
                </Link>
                <br />
                <Link className="Company-Content-Table-Body-Link" target="_blank" href="https://www.ajinomoto.co.jp/">
                  味の素株式会社
                </Link>
                <br />
                <Link className="Company-Content-Table-Body-Link" target="_blank" href="https://www.aba-lab.com/">
                  株式会社aba
                </Link>
                <br />
                株式会社iKURA Design&Solutions
                <br />
                <Link className="Company-Content-Table-Body-Link" target="_blank" href="https://www.efu-addwis.co.jp/">
                  株式会社イーエフユーアドウィス
                </Link>
                <br />
                <Link className="Company-Content-Table-Body-Link" target="_blank" href="https://electoria.jp/">
                  株式会社Electoria
                </Link>
                <br />
                <Link className="Company-Content-Table-Body-Link" target="_blank" href="https://xmart.co.jp/">
                  クロスマート株式会社
                </Link>
                <br />
                株式会社Q ONE
                <br />
                <Link className="Company-Content-Table-Body-Link" target="_blank" href="http://www.sagawaelectronics.com/">
                  佐川電子株式会社
                </Link>
                <br />
                <Link className="Company-Content-Table-Body-Link" target="_blank" href="https://gyrostudio.co.jp/">
                  株式会社ジャイロスタジオ
                </Link>
                <br />
                <Link className="Company-Content-Table-Body-Link" target="_blank" href="https://www.jobcrown.co.jp/">
                  株式会社ジョブクラウン
                </Link>
                <br />
                <Link className="Company-Content-Table-Body-Link" target="_blank" href="https://studioz.co.jp/">
                  Studio Z株式会社
                </Link>
                <br />
                <Link className="Company-Content-Table-Body-Link" target="_blank" href="https://www.starfactory-inc.co.jp/">
                  株式会社スターファクトリー
                </Link>
                <br />
                <Link className="Company-Content-Table-Body-Link" target="_blank" href="https://smiee.jp/">
                  株式会社Smiee
                </Link>
                <br />
                <Link className="Company-Content-Table-Body-Link" target="_blank" href="https://7volt.com/">
                  株式会社セブンボルト
                </Link>
                <br />
                <Link className="Company-Content-Table-Body-Link" target="_blank" href="https://tghc.tsunagu-grp.jp/">
                  株式会社ツナググループHC
                </Link>
                <br />
                <Link className="Company-Content-Table-Body-Link" target="_blank" href="https://vantan.jp/">
                  株式会社バンタン
                </Link>
                <br />
                <Link className="Company-Content-Table-Body-Link" target="_blank" href="https://www.first-ops.com/">
                  株式会社ファーストオペレーションズ
                </Link>
                <br />
                株式会社喜喜企画
                <br />
                <Link className="Company-Content-Table-Body-Link" target="_blank" href="https://velero.jp/">
                  合同会社Velero
                </Link>
                <br />
                <Link className="Company-Content-Table-Body-Link" target="_blank" href="http://www.melmax.co.jp/">
                  株式会社メルメクス
                </Link>
                <br />
                <Link className="Company-Content-Table-Body-Link" target="_blank" href="https://www.nissan.co.jp/">
                  日産自動車株式会社
                </Link>
                <br />
                <Link className="Company-Content-Table-Body-Link" target="_blank" href="https://polms.co.jp/">
                  ポルムス株式会社
                </Link>
                <br />
                マナルカ合同会社
                <br />
                <Link className="Company-Content-Table-Body-Link" target="_blank" href="https://www.ubg.co.jp/">
                  株式会社UBG
                </Link>
                <br />
                WiredForest 合同会社
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const sx: SxProps<Theme> = (theme) => ({
  "&.Company": {
    width: "100%",
    background: "#22A1B9",
    color: "#FFFFFF",
    padding: "64px 0 92px",
  },
  ".Company-Title": {
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Avenir Next",
    fontWeight: 700,
    fontSize: {
      md: "64px",
      xs: "40px",
    },
    lineHeight: "88px",
    letterSpacing: "0.15em",
  },
  ".Company-Title-Body": {
    opacity: 0.6,
    fontWeight: 500,
  },
  ".Company-SubTitle": {
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 700,
    fontSize: {
      md: "16px",
      xs: "14px",
    },
    lineHeight: {
      md: "24px",
      xs: "20px",
    },
    letterSpacing: "1px",
  },
  ".Company-Content": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "72px",
  },
  ".Company-Content-Table": {
    width: {
      md: "880px",
      xs: "400px",
    },
  },
  ".Company-Content-Table-Head": {
    color: "#FFFFFF",
    fontWeight: 700,
    fontSize: {
      md: "20px",
      xs: "16px",
    },
    lineHeight: {
      md: "30px",
      xs: "24px",
    },
    "&.Supplier": {
      display: "flex",
      alignItems: "flex-start",
      border: "none",
    },
  },
  ".Company-Content-Table-Body": {
    color: "#FFFFFF",
    fontWeight: 400,
    fontSize: {
      md: "20px",
      xs: "16px",
    },
    lineHeight: {
      md: "30px",
      xs: "24px",
    },
    "&.Supplier": {
      border: "none",
    },
  },
  ".Company-Content-Table-Body-Link": {
    color: "#FFFFFF",
  },
});
