import headerTecnoLogo from "../../resources/images/header_tecno_logo.png";

import {
  Grid,
  Box,
  SxProps,
  Theme,
  ButtonGroup,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { Link as Scroll } from "react-scroll";
import { useState } from "react";

export const Header = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };
  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const itemList = [
    { text: "ABOUT", link: "About", pcClass: "Header-Button", spClass: "Header-Drawer-List-Button"},
    { text: "SERVICE", link: "Service", pcClass: "Header-Button", spClass: "Header-Drawer-List-Button"},
    { text: "COMPANY", link: "Company", pcClass: "Header-Button", spClass: "Header-Drawer-List-Button" },
    { text: "CONTACT", link: "Contact", pcClass: "Header-Button-Contact", spClass: "Header-Drawer-List-Button-Contact"},
  ];

  return (
    <Grid container sx={sx} className="Header">
      <Box className="Header-Logo" component="img" alt="テクノスタンダード" src={headerTecnoLogo} />
      <ButtonGroup variant="text">
        {itemList.map((item,index) => (
          <Scroll to={item.link} key={index} smooth={true} duration={500}>
            <Button className={item.pcClass}>{item.text}</Button>
          </Scroll>
        ))}
      </ButtonGroup>
      <Button className="Header-IconButton" onClick={handleDrawerOpen} sx={{ ...(drawerOpen && { display: "none" }) }}>
        <MenuIcon className="Header-IconButton-Menu" />
      </Button>
      <Drawer sx={sx} anchor={"top"} open={drawerOpen} onClose={handleDrawerClose}>
        <Box className="Header-Menu">
          <Box className="Header-Menu-Header">
            <Box className="Header-Menu-Header-Logo" component="img" alt="テクノスタンダード" src={headerTecnoLogo} />
            <Button className="Header-Menu-Header-Close" onClick={handleDrawerClose}>
              <CloseIcon className="Header-Menu-Header-Close-Icon" />
            </Button>
          </Box>
          <List className="Header-Drawer-List">
            {itemList.map((item, index) => (
              <Scroll to={item.link} key={index} smooth={true} duration={500} onClick={handleDrawerClose}>
                <ListItem disablePadding>
                  <ListItemButton className={item.spClass}>
                    <ListItemText className="Header-Drawer-List-Button-Text">{item.link}</ListItemText>
                  </ListItemButton>
                </ListItem>
              </Scroll>
            ))}
          </List>
        </Box>
      </Drawer>
    </Grid>
  );
};

const sx: SxProps<Theme> = (theme) => ({
  "&.Header": {
    position: "absolute",
    alignItems: "center",
    justifyContent: "space-between",
  },
  ".Header-Logo": {
    marginLeft: {
      md: "60px",
      xs: "24px",
    },
  },
  ".Header-Button": {
    fontFamily: "Avenir Next",
    fontWeight: 600,
    fontSize: "18px",
    height: "80px",
    width: "110px",
    color: "#FFFFFF",
    borderRadius: 0,
    display: {
      md: "flex",
      xs: "none",
    },
  },
  ".Header-Button-Contact": {
    fontFamily: "Avenir Next",
    fontWeight: 600,
    fontSize: "18px",
    height: "80px",
    width: "110px",
    backgroundColor: "#22A1B9",
    color: "#FFFFFF",
    borderRadius: 0,
    "&:hover": {
      color: "#22A1B9",
      backgroundColor: "#FFFFFF",
    },
    display: {
      md: "flex",
      xs: "none",
    },
  },
  ".Header-IconButton": {
    height: "68px",
    width: "68px",
    backgroundColor: "#22A1B9",
    color: "#FFFFFF",
    borderRadius: 0,
    display: {
      md: "none",
      xs: "flex",
    },
    "&:hover": {
      backgroundColor: "#22A1B9",
    }
  },
  ".Header-IconButton-Menu": {
    height: "100%",
    width: "100%",
  },
  ".Header-Menu": {
    backgroundColor: "#22A1B9",
    color: "#FFFFFF",
  },
  ".Header-Menu-Header": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  ".Header-Menu-Header-Logo": {
    marginLeft: "24px",
  },
  ".Header-Menu-Header-Close": {
    height: "68px",
    width: "68px",
    backgroundColor: "#FFFFFF",
    borderRadius: 0,
  },
  ".Header-Menu-Header-Close-Icon": {
    height: "100%",
    width: "100%",
  },
  ".Header-Drawer-List": {
    margin: "92px 20px",
    padding: "0",
  },
  ".Header-Drawer-List-Button": {
    padding: "12px 0",
    borderTop: "1px solid rgba(247, 248, 248, 0.3)",
  },
  ".Header-Drawer-List-Button-Contact": {
    padding: "12px 0",
    borderTop: "1px solid rgba(247, 248, 248, 0.3)",
    backgroundColor: "#FFFFFF",
    color: "#22A1B9",
    "&:hover": {
      backgroundColor: "#FFFFFF",
      color: "#22A1B9",
    },
  },
  ".Header-Drawer-List-Button-Text": {
    fontFamily: "Avenir Next",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "20px",
    letterSpacing: "2px",
    textAlign: "center",
  },
});
