import { createTheme, PaletteColor, PaletteColorOptions } from "@mui/material";

export const baseTheme = createTheme({
  palette: {
    primary: {
      main: "#22A1B9",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 374,
      md: 768,
      lg: 1024,
      xl: 2000,
    },
  },
  typography: {
    fontFamily:
      '"Noto Sans JP", "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif',
  },
});

declare module "@mui/material/styles" {
  interface Palette {
    user: PaletteColor;
  }
  interface PaletteOptions {
    user?: PaletteColorOptions;
  }
}
