import { Grid, SxProps, Theme } from "@mui/material";
import { Header } from "../containers/Header/Header";
import { MainVisual } from "src/containers/MainVisual/MainVisual";
import { About } from "src/containers/About/About";
import { Service } from "src/containers/Service/Service";
import { Company } from "src/containers/Company/Company";
import { Contact } from "src/containers/Contact/Contact";
import { Footer } from "../containers/Footer/Footer";

export const TopPage = () => {
  return (
    <Grid container className="TopPage" sx={sx}>
      <Header />
      <MainVisual />
      <About />
      <Service />
      <Company />
      <Contact />
      <Footer />
    </Grid>
  );
};

const sx: SxProps<Theme> = (theme) => ({
  "&.TopPage": {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    minWidth: {
      md: "768px",
      xs: "374px",
    },
  },
});
