import { Box, SxProps, Theme, Grid } from "@mui/material";

export const Contact = () => {
  return (
    <Box sx={sx} className="Contact">
      <Grid container className="Contact-Title">
        <span className="Contact-Title-Head">C</span>ONTACT
      </Grid>
      <Grid container className="Contact-SubTitle">
        お問い合わせ
      </Grid>
      <Box className="Contact-Complain">
        弊社にご関心をお持ちいただき、ありがとうございます。
        <br />
        サービスに関するご相談など、お気軽にお問い合わせください。
        <br />
        お問合せ内容の確認後、担当者よりご連絡致します。
        <br />
        下記よりお問い合わせになりたい項目をご選択ください。
        <br />
      </Box>
      <Box className="Contact-Form">
        <iframe
          title="contact-form"
          src="https://docs.google.com/forms/d/e/1FAIpQLSciBgT4AWbzwA-8Hhl25YoN2VPdjojFcr_qrV9ZtfjfUuoc5A/viewform?embedded=true"
          width="640"
          height="1200"
          frameBorder="0"
        >
          読み込んでいます…
        </iframe>
      </Box>
    </Box>
  );
};

const sx: SxProps<Theme> = (theme) => ({
  "&.Contact": {
    width: "100%",
    padding: "64px 0 92px",
  },
  ".Contact-Title": {
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Avenir Next",
    fontWeight: 500,
    fontSize: {
      md: "64px",
      xs: "40px",
    },
    lineHeight: "88px",
    letterSpacing: "0.15em",
  },
  ".Contact-Title-Head": {
    color: "#22A1B9",
    fontWeight: 700,
  },
  ".Contact-SubTitle": {
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 700,
    fontSize: "16px",
  },
  ".Contact-Complain": {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "30px",
    textAlign: "center",
    marginTop: "32px",
  },
  ".Contact-Complain-BrSp": {
    display: {
      md: "none",
      xs: "block",
    },
  },
  ".Contact-Form": {
    marginTop: "52px",
    display: "flex",
    justifyContent: "center",
  },
});
