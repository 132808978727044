import { Box, SxProps, Theme } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import { useGoogleTagManager } from "./hooks/useGoogleTagManager";
import { TopPage } from './page/TopPage';

export const App = () => {
  // GTM 用の処理を実行
  useGoogleTagManager();

  return (
    <Box className="App" sx={sx}>
      <Routes>
        {/* トップページ */}
        <Route path="/*" element={<TopPage />} />
      </Routes>
    </Box>
  );
};

const sx: SxProps<Theme> = {
};
