import { useEffect } from "react";

declare let NicoGoogleTagManagerDataLayer: object[];

/**
 * GTM 用のタグを検索して見つけたら NicoGoogleTagManagerDataLayer に push する
 * data-gtm="hoge_event" な形で data 属性を付与して利用する
 */
const searchAndPushGtmEvent = (event: MouseEvent) => {
  let target = event.target;
  if (target == null) return;

  let count = 0;
  while (count++ < 10) {
    if (!(target instanceof HTMLElement)) return;
    if ("gtm" in target.dataset) {
      NicoGoogleTagManagerDataLayer.push({ event: target.dataset.gtm });
      return;
    }
    target = target.parentNode;
  }
};

export const useGoogleTagManager = () => {
  useEffect(() => {
    window.document.body.addEventListener("click", searchAndPushGtmEvent);
    return () => {
      window.document.body.removeEventListener("click", searchAndPushGtmEvent);
    };
  }, []);
};
